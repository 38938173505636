<template>
    <div class="body">
        <div class="container">
            <div class="header">
                <div class="tabs">
                    <div class="tab-item cur">全部</div>
                   <!-- <div class="tab-item">进行中</div>
                    <div class="tab-item">已完成</div> -->
                </div>
                <div class="line full-width"></div>
            </div>
            <div class="task-list full-width with-footer">
                <div class="task-item" v-for="(item, index) in list" :key="index" 
                  @click="toDetail(item)">
                    <div class="task-title">
                        <!-- start finish -->
                        <!-- <img class="status" src="../assets/images/start.png"> -->
                        {{item.name}}
                    </div>
                    <div class="task-desc">
                        <div class="state-2" v-if="!item.ts">
                            <span class="time">剩{{item.lifespan_days}}天</span>
                            <van-button class="small-btn" plain color="#465acd" size="small" @click.stop="takeTask(item.tid)">领取</van-button>
                        </div>
                        <div class="state-1" v-else>
                            <span class="time">{{item.ts.start_time}}~{{item.ts.end_time}}</span>
                            <div class="" style="width: 8rem; padding-right: 5px;"> 
                                <van-progress :percentage="item.ts.speed_num" pivot-text="" />{{item.ts.speed_num}}%
                            </div>
                        </div>
                        <!-- <img class="item-arrow" src="../assets/images/arrow.png" alt="" srcset=""> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {toast , checkLogin} from '@/utils/common.js';
    import { getTaskList, takeTask} from '@/api/task.js';
    import { mapState } from 'vuex'
    export default{
        data(){
            return {
                value: 20,
                list: null,
            }
        },
        activated(){
            checkLogin().then(()=> {
                this.getTaskList();
            })
            this.hideTab();
         },
         computed: mapState(['token']),
         methods:{
            getTaskList(){
                getTaskList().then((ret)=>{
                    this.list = ret.data;
                });
             },
             toDetail(item){
                 // 没有领取，不可进入
                 
                 if(!item.ts){
                     return toast('请先领取任务！');
                 }
                 var id = item.id, tsid = item.ts.id;
                this.$router.push(`/task-detail?id=${id}&tsid=${tsid}`);
             },
             takeTask(tid){
                 takeTask(tid).then(()=>{
                     this.getTaskList();
                 });
             },
             hideTab(){
                 //console.log(document.querySelector('#vantab'));
                 document.querySelector('#vantab').classList.remove('tab-hide');
             }
         },
    }
</script>

<style scoped>
    @import url('../../assets/css/common.css');
    .container{
        bottom: 50px;
    }
    .line{
        height: 1px;
        background-color: #ccc;
        margin: 10px 0 10px;
    }
    .tabs{
        display: flex;
        margin: 10px 0 6px;
        font-size: 14px;
        font-weight: 400;
    }
    .tab-item{
        margin-right: 10px;
    }
    .tab-item.cur{
        color: #465acd;
    }
    .task-list{
        /* padding: 1.5rem; */
        /* background-color: #f2f2f2; */
    }
    .task-item{
        /* width: 34.5rem; */
        padding: 0.4rem 0.8rem;
        background-color: #fff;
        margin-bottom: 4px;
        font-size: 14px;
        /* line-height: 1.6; */
        border-bottom: 1px dashed #ccc;
        position: relative;
        line-height: 1.6;
    }
    .task-item .item-arrow{
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        right: 5px;
        top: 20px;
        
    }
    .task-list .task-title{
        font-size: 16px;
        color: #333;
        display: flex;
    }
    .task-list .task-desc > *{
        display: flex;
        justify-content: space-between;
        color: #999;
        /* padding-left: 20px; */
        align-items: center;
    }
    .task-list .status{
        width: 24px;
        height: 24px;
        
        /* position: absolute; */
        top: 0;
        left: 0;
        bottom: 0;
    }
    .task-list .small-btn{
        height: 26px;
    }
    .van-tabbar--fixed{
        display: none !important;
    }
</style>